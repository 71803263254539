body {
  overscroll-behavior: none
}
body.registration {
    overflow: auto !important;
    height: auto !important;
}

body.signing {
    overflow: auto !important;
}

.link {
    text-decoration: underline !important;
}

.cursor {
    cursor: pointer;
}

.dark-indigo .app-logo-content {
   /* background-color: #8a5e87 !important;*/
}

.dark-indigo .app-main-header {
  /*  background-color: #8a5e87 !important;
    color: #fff !important;*/
}

.picker {
    margin: 16px 0px;
}


.minWidth320 {
    min-width: 320px;
}

.redux-lazy-scroll {
    overflow: auto !important;
}

.preloader-icon  {
    display: inline-block;
}

.list .input-group {
    max-width: 300px;
}

.list .input-group+a {
    width: 38px;
    height: 38px;
    position: relative;
    font-size: 25px;
}

.swal2-modal .swal2-buttonswrapper.swal2-loading .swal2-styled.swal2-confirm {
    vertical-align: middle;
}

.DateInput_input {
    padding: 5px 12px 5px;
}

.SingleDatePickerInput__withBorder,
.DateRangePickerInput__withBorder {
    border-radius: .25rem;
}

.CalendarMonth_caption {
    padding-bottom: 50px;
}

.SingleDatePicker_picker__portal,
.DateRangePicker_picker__portal,
.DateRangePicker_picker__fullScreenPortal {
    z-index: 2000;
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover,
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background-color: transparent;
}

.dz-remove {
    position: absolute;
    top: -28px;
    z-index: 10;
}

.dropzone
{
    min-height: 135px;
    position: relative;
    border: 2px dashed #009da0;
    background: #f5f7fa;
}
.dropzone .dz-message
{
    font-size: 2rem;

    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    height: 300px;
    margin-top: -30px;

    text-align: center;

    color: #009da0;
}
.dropzone .dz-message:before
{
    font-family: 'feather';
    font-size: 80px;
    font-weight: normal;
    line-height: 1;

    position: absolute;
    z-index: 2;
    top: 48px;
    left: 50%;

    display: inline-block;

    width: 80px;
    height: 80px;
    margin-left: -40px;

    content: '\e94b';
    text-indent: 0;

    color: #009da0;

    -webkit-font-smoothing: antialiased;
}
.dropzone .dz-preview.dz-image-preview
{
    background: transparent;
}
.dropzone .dz-preview .dz-remove
{
    font-size: 1.1rem;
    line-height: 2rem;
}
.dropzone .dz-preview .dz-remove:before
{
    font-family: 'feather';
    font-weight: normal;
    line-height: 1;

    z-index: 2;

    display: inline-block;

    content: '\e9e6';
    text-indent: 0;

    -webkit-font-smoothing: antialiased;
}
.dropzone .dz-preview .dz-remove:hover
{
    text-decoration: none;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -ms-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    width: 40px;
    height: 40px;
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -18px;
    margin-left: -23px;
    border: 8px solid rgba(255,255,255,0.4);
    border-top: 8px solid rgba(255,255,255,0.9);
    background: transparent;
    -webkit-transform: scale(1);
    border-radius: 50%;
    overflow: hidden;
}

.dropzone .dz-message {
    margin-top: -108px !important;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.formBuilderItem .btn {
    position: relative !important;
    top: -33px;
    left: 0px;
    float: right;
    margin-left: 10px !important;
}

form .form-body {
    overflow: visible;
}

.card.card-inverse input {
    color: #75787d !important;
}

.formBuilderItem .form-group.error .help-block,
.formBuilderItem .form-group.error .help-inline {
    color: #fff !important;
}

.formBuilderItem .form-group.error input,
.formBuilderItem .form-group.error select,
.formBuilderItem .form-group.error textarea {
    border: 1px solid #a6a9ae !important;
}

.react-tagsinput {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;

}

.react-tagsinput-input {
    margin-bottom: 0px;
    padding: 0px 0px;
    font-size: 1rem;
    line-height: 1.5;
}

.react-tagsinput-tag {
    color: #638421 !important;
    margin-bottom: 0px;
    padding: 0px 5px;
}

.rbc-month-view {
    display: block;
}

.rbc-month-row {
    min-height:65px;
}

.map {
    width: 100%;
    height: 250px;
}

.timer {
    position: fixed;
    bottom: 15px;
    right: 45px;
}
.spacer_display_one {
  display:none;
}
.table-responsive-material td,
.table-responsive-material th {
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    padding: 4px 12px 4px 12px;
}
.table-responsive-material tbody tr:hover {
  background-color: #ddd!important;
}
.arrowPos {
    position: absolute;
    right: 2px;
    top: 8px;
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
}
.arrowPos:hover {
    background-color: rgba(70, 70, 70, 0.75);
    color: white;
}

.helper-text {
    margin: 0;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 8px;
    min-height: 1em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1em;
    color: #f44336;
}

.signature {
    width: 100%;
    height: 120px;
    border-radius: 2px;
    border: 3px solid #999;
}

.redAutocomplete * {
    color: red !important
}

.jr-btn-xs {
    padding: 2px 5px !important;
}

.table-responsive-material tr {
    height: auto !important;
}

.table-responsive-material td, .table-responsive-material th {
    padding: 2px 10px 2px 10px !important;
}

.table-responsive-material.view td, .table-responsive-material.view th {
    padding: 4px 12px 4px 12px !important;
}

.autocompleteFilter {
    overflow: visible !important;
}

.jr-btn:not(:last-child), .jr-fab-btn:not(:last-child), .btn:not(:last-child) {
    margin-right: 5px !important;
}

.jr-card-social .social-link {
    justify-content: flex-end !important;
}

.isViewPage .col-12 {
    border: 1px solid #fff;
    background-color: #f8f9fa;
}

.isViewPage label {
    margin-bottom: 0;
    max-width: none !important;
    flex: none !important;
    font-weight: bold;
    width: auto !important;
}

.isViewPage h2 {
    margin-bottom: 0px !important;
}

.isViewPage .form-group {
    margin-bottom: 0px !important;
}

.modal-centered {
    margin: auto;
}

.remaning {
    padding: 2px 5px;
    font-weight: bold;
    font-size: 12px;
}

div[tabindex="-1"] {
    z-index: auto !important;
}

.form-legend {
  /*border-top:solid 1px #cccccc;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;*/
  margin-bottom: 6px;
  color: #bbbbbb;
  font-size: 13px;
  font-weight: bold;
  /*text-shadow: 0px 0px 1px rgb(90, 90, 90, 0.8);*/
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}
.badgeRight {
  top: 50%!important;
  right: -30px!important;
  margin-top: -11px;
  cursor: pointer;
  transform: inherit !important
}
.badgeRight:hover {
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
}
div.reregisterSection {
  margin-top: 24px;
}
div.reregisterSection:nth-child(1) {
  margin-top: 0;
}
.reregisterSection-title {
  border-bottom: solid 1px #ddd;
  font-weight: bold;
  padding-bottom: 3px;
}

.reregisterSection-item-changed {
  background-color: #fbe9e7;
}
