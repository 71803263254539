  .slick-slide {
    position: relative;
    background-size: cover;
    margin-bottom: -6px;
  }
  .slick-slide:before {
    position: absolute;
    background: #a3a3a3;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 6px);
    opacity: 0.5;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
    content: ' ';
    z-index: 1;
  }
  
  .slick-slide.slick-current {
    z-index: 2;
  }
  .slick-slide.slick-current:before {
    opacity: 0;
  }

  .slick-dots {
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  .slick-dots li {
    width: auto;
    height: 4px;
    margin: 0 10px;
  }
  
  .slick-dots li button {
    height: 4px;
    background: #fff;
    padding: 0;
    opacity: 1;
    width: 35px;
  }
  
  @media screen and (min-width: 768px) {
    .slick-dots li button {
      width: 95px;
    }
  }
  
  .slick-dots li button:before {
    display: none;
  }
  
  .slick-dots .slick-active button {
    background: #8a5e87;
  }